<template>
  <div>
    <table-with-pagination
      :tableData="tableData"
      :columnList="columnList"
      :pagination.sync="pagination"
      :sortInfo.sync="updateSortInfo"
      @current-change="getTableList"
    />
  </div>
</template>
<script>
import DeviceApi from '@/api/device';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';

export default {
  name: 'DEVICE_TABLE',
  mixins: [TablePaginationMixin],
  props: {
    customerInfo: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    customerInfo: {
      handler () {
        this.getTableList();
      },
      deep: true,
    },
  },
  data () {
    return {
      tableData: [],
      columnList: [
        {
          tooltip: true,
          label: '设备名称',
          minWidth: '160px',
          prop: 'equipmentName',
        },
        {
          tooltip: true,
          label: '规格型号',
          minWidth: '150px',
          prop: 'model',
        },
        {
          tooltip: true,
          label: '品牌',
          minWidth: '150px',
          prop: 'brand',
        },
        {
          tooltip: true,
          label: '生产商',
          minWidth: '150px',
          prop: 'manufacturer',
        },
        {
          tooltip: true,
          label: '注册证编号/备案号',
          minWidth: '150px',
          prop: 'registrationNumber',
        },
        {
          tooltip: true,
          label: '设备描述',
          minWidth: '150px',
          prop: 'deviceDescription',
        },
        {
          tooltip: true,
          label: '备注',
          minWidth: '150px',
          prop: 'remark',
        },
      ],
    };
  },
  methods: {
    getTableList () {
      if (!this.customerInfo.customerId) {
        return;
      }

      const params = {
        ...this.customerInfo,
        enable: true,
      };

      DeviceApi.getDeviceList({
        ...params,
        ...this.pagination,
        pageNum: this.pagination.currentPage,
      }).then((res) => {
        this.tableData = res.body.list ?? [];
        this.pagination.total = res.body.total;
      });
    },
  },
};
</script>
